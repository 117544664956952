import React, { useRef, useState, useEffect, useContext } from "react";
import logo from "../image/ada-logo.svg";
import "../css/login.sass";
import {
    InputAdornment,
    IconButton,
    TextField,
    FormControl,
    OutlinedInput,
    InputLabel,
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { post } from "../utils/api";
import apiServer from "../constants/apiServer";
import { AuthContext } from "../context/AuthProvider";
import { Navigate } from "react-router-dom";
import AdaAlert from "../common/AdaAlert";
// import jwt_decode from "jwt-decode";

const Login = () => {
    const emailRef = useRef();

    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
    });

    const [showPassword, setShowPassword] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const { setAuth } = useContext(AuthContext);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLoginInfo((prevInfo) => {
            return {
                ...prevInfo,
                [name]: value,
            };
        });
    };

    const { email, password } = loginInfo;

    const handleLogin = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append("email", loginInfo.email);
        formData.append("password", loginInfo.password);
        try {
            const { data } = await post(apiServer.auth.login, formData);
            if (data.code === 200) {
                console.log("login ", data.code, data.data);
                const token = data?.data?.token;
                // console.log("token decode::", token, jwt_decode(token));
                localStorage.setItem("token", token);
                localStorage.setItem("admin", JSON.stringify(data.data.data));
                setAuth([
                    {
                        token: data.data.token,
                        identity: data.data.data,
                        permission: data.data.data.id,
                    },
                ]);
                setSuccess(data.message);
            } else if (data.code === 400) {
                setAuth([
                    {
                        identity: "",
                        permission: "",
                    },
                ]);
                setError(data.message);
            } else if (data.code >= 400) {
                setAuth([
                    {
                        identity: "",
                        permission: "",
                    },
                ]);
                setError("Email or Password should not be null");
            }
        } catch (err) {
            console.log("error is ", err);
            setAuth({
                identity: "",
                permission: "",
            });
            setError(err.message);
        }
        setShowPassword(false);
    };

    useEffect(() => {
        emailRef.current.focus();
    }, []);

    return (
        <>
            {success && <Navigate to="/dashboard" replace="true" />}
            <div className="login-page-section">
                <img src={logo} alt="ada-logo" className="login-logo-img" />
                <div className="login-container">
                    <h2>Login</h2>
                    {/* <img src={logo} alt="ada-logo"/> */}
                    <form onSubmit={handleLogin}>
                        {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
                        <TextField
                            id="email"
                            label="Email *"
                            type="email"
                            name="email"
                            value={email}
                            ref={emailRef}
                            fullWidth
                            onChange={handleChange}
                        />
                        <FormControl>
                            <InputLabel htmlFor="standard-adornment-password">
                                Password
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                value={password}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword(!showPassword);
                                            }}
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                            }}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <MdVisibilityOff />
                                            ) : (
                                                <MdVisibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        <button className="login-btn">Login</button>
                    </form>
                    {/* <span>Need an account ? </span> */}
                </div>
                <span>(V-1.0.9)</span>
                {error && <AdaAlert message={error} type="error" />}
                {success && <AdaAlert message={success} type="success" />}
            </div>
        </>
    );
};

export default Login;
